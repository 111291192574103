<template>
    <div class="workList">
        <StatisticsInfo :list="list" />
        <el-card class="content">
            <el-tabs v-model="activeName" type="card" @tab-click="doSearch">
                <el-tab-pane v-for="tab in WORKOPTIONS" :key="tab.value" :label="tab.label" :name="String(tab.value)">
                    <el-form :inline="true" :model="searchForm">
                        <el-form-item label="搜索内容">
                            <el-input class="w350" v-model="searchForm.keyWord" placeholder="工单编号/预约人姓名/电话" clearable></el-input>
                        </el-form-item>
                        <el-form-item :label="`${activeName === '4' ? '服务' : activeName === '5' ? '完成' : activeName === '6' ? '取消' : '预约'}时间`">
                            <el-date-picker
                                v-model="timeRange"
                                value-format="yyyy-MM-dd"
                                clearable
                                @change="dateTimeChange"
                                type="daterange"
                                range-separator="至"
                                unlink-panels
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="所属组织">
                            <el-cascader
                                clearable
                                placeholder="请选择所属组织"
                                v-model="streetArr"
                                :props="defaultProps"
                                class="w350"
                                ref="supervisedUnitCode"
                                :show-all-levels="false"
                                filterable
                                :options="streetList"
                            />
                        </el-form-item>
                        <template v-if="hasBed">
                            <el-form-item label="老人能力">
                                <el-select class="w350" v-model="searchForm.elderlyAbility" placeholder="请选择老人能力" clearable>
                                    <el-option v-for="item in ELDERLYABILITY" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="老人类型">
                                <el-select class="w350" v-model="searchForm.elderlyType" placeholder="请选择老人类型" clearable>
                                    <el-option v-for="item in ELDERLYTYPE" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                        <el-form-item label="服务机构">
                            <el-select class="w350" v-model="searchForm.serviceId" placeholder="请选择服务机构" filterable clearable>
                                <el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="服务项目">
                            <el-cascader
                                ref="comboCascader"
                                v-model="searchForm.serviceItemId"
                                :options="comboOptions"
                                :props="{ value: 'id' }"
                                class="w350"
                                clearable
                                placeholder="请选择服务项目"
                                @change="changeService"
                            />
                        </el-form-item>
                        <el-form-item label="服务人员" v-if="activeName !== '1' && activeName !== '2'">
                            <el-select class="w350" v-model="searchForm.staffId" placeholder="请选择服务人员" filterable clearable>
                                <el-option v-for="item in staffList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="评价状态" v-if="activeName === '5'">
                            <el-select class="w350" v-model="searchForm.buyerRate" placeholder="请选择评价状态" filterable clearable>
                                <el-option label="未评价" :value="0"></el-option>
                                <el-option label="已评价" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="工单状态" v-if="activeName === '4' || activeName === '5' || activeName === '6'">
                            <el-select class="w350" v-model="searchForm.exception" placeholder="请选择工单状态" filterable clearable>
                                <el-option label="正常工单" :value="0"></el-option>
                                <el-option label="签到异常工单" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="doSearch">搜索</el-button>
                        </el-form-item>
                        <el-button type="primary" plain style="float: right" @click="doExport">导出</el-button>
                    </el-form>
                    <el-table style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle" v-loading="loading" @sort-change="sortChangeInit">
                        <template slot="empty">
                            <IsEmpty />
                        </template>
                        <el-table-column prop="workOrderId" label="工单编号" align="center" width="210" fixed="left">
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center">
                                    <img v-if="scope.row.isException !== 0" style="margin-right: 5px" src="../../assets/imgs/warn.png" alt="" />
                                    <span :style="{ color: scope.row.isException === 0 ? '#606266' : '#ff5a59', marginLeft: scope.row.isException === 0 ? '25px' : 0 }">
                                        {{ scope.row.workOrderId }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="activeName === '1'" prop="createTime" label="生成时间" align="center" width="180" sortable="custom" />
                        <el-table-column v-else prop="createTime" label="创建时间" align="center" width="180" sortable="custom" />
                        <el-table-column prop="apptName" label="预约人姓名" align="center" width="140" />
                        <el-table-column prop="apptPhone" label="预约人电话" align="center" width="140" />
                        <el-table-column v-if="hasBed" prop="elderlyAbility" label="老人能力评估" align="center" width="140" />
                        <el-table-column v-if="hasBed" prop="elderlyType" label="老人类型" align="center" width="140" :show-overflow-tooltip="true" />
                        <el-table-column prop="organizationName" label="预约人所属组织" align="center" width="200" :show-overflow-tooltip="true">
                            <!-- <template slot-scope="scope">
								<span>{{ scope.row.tenantName ? `${scope.row.tenantName}/${scope.row.organizationName}` : '--' }}</span>
							</template> -->
                        </el-table-column>
                        <el-table-column prop="serviceAddress" label="服务地址" align="center" width="140" :show-overflow-tooltip="true" />
                        <el-table-column
                            prop="apptTime"
                            align="center"
                            :label="`${activeName === '4' ? '服务' : activeName === '5' ? '完成' : activeName === '6' ? '取消' : '预约'}时间`"
                            width="180"
                            sortable="custom"
                        />
                        <el-table-column prop="serviceItemName" label="服务项目" align="center" width="160" :show-overflow-tooltip="true" />
                        <el-table-column prop="quantity" label="数量" align="center" width="140" />
                        <el-table-column prop="exercisePrice" label="执行价格(元)" align="center" width="140" />
                        <el-table-column prop="serviceName" label="服务机构" align="center" width="180" />
                        <el-table-column v-if="activeName !== '1' && activeName !== '2'" prop="staffName" label="服务人员" align="center" width="140" />
                        <el-table-column v-if="activeName !== '1' && activeName !== '2'" prop="staffPhone" label="服务人员电话" align="center" width="140" />
                        <el-table-column prop="orderId" label="关联订单编号" align="center" width="180" />
                        <el-table-column v-if="activeName === '5'" prop="serviceDuration" label="服务时长" align="center" width="140" />
                        <el-table-column v-if="activeName === '5'" prop="rateMark" label="评分" align="center" width="160" />
                        <el-table-column prop="address6" label="操作" min-width="150" align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" @click="seeDetail(scope.row)">查看详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="tableParams.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="tableParams.pageSize"
                        layout="total, sizes, prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
        </el-card>
        <!-- 工单详情 -->
        <OrderDetail :orderServiceOgr="orderServiceOgr" :drawerShow.sync="drawerShow" :orderId="orderId" />
    </div>
</template>

<script>
import OrderDetail from '../OlderBed/components/orderDetail.vue'
import StatisticsInfo from './componenets/statisticsInfo.vue'
import { ELDERLYABILITY, ELDERLYTYPE, WORKOPTIONS } from '@/data/dataDictionary'
import { streetList, serviceNameList, backCascader2 } from '@/api/orderManage'
import { getCount, getWorkOrderPage, hksStaffList, WorkOrderPageDown } from '@/api/workManage'
import { getContent, downloadStream } from '@/utils/index'
export default {
    // 服务工单
    name: 'workList',
    components: {
        OrderDetail,
        StatisticsInfo
    },
    watch: {
        $route: {
            handler(val) {
                this.hasBed = val.path === '/serveDetail'
                this.activeName = '1'
                this.searchForm.bed = val.path === '/serveDetail' ? 1 : 2
                this.getCountInfo()
                this.getList()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            ELDERLYABILITY,
            ELDERLYTYPE,
            WORKOPTIONS,
            hasBed: true, // 是否有床位
            orderServiceOgr: '', //服务机构名字
            list: [
                { url: require('../../assets/imgs/confirmed.png'), num: 18, title: '待确认工单' },
                { url: require('../../assets/imgs/allocated.png'), num: 0, title: '待分配工单' },
                { url: require('../../assets/imgs/served.png'), num: 0, title: '待服务工单' },
                { url: require('../../assets/imgs/service.png'), num: 0, title: '服务中工单' },
                { url: require('../../assets/imgs/finishWork.png'), num: 0, title: '已完成工单' },
                { url: require('../../assets/imgs/cancel.png'), num: 0, title: '已取消工单' }
            ],
            activeName: '1',
            drawerShow: false,
            searchForm: {
                bed: 1, // 显示所有的工单
                startTime: '', // 开始时间
                endTime: '', // 结束时间
                elderlyAbility: null, // 老人能力评估
                elderlyType: null, // 老人类型
                streetId: null, // 机构ID
                serviceId: null, // 服务机构id
                serviceItemId: null, // 服务项目id
                staffId: null, // 服务人员ID
                buyerRate: null, // 评价状态
                exception: null, // 工单状态
                keyWord: '' // 搜索内容
            },
            timeRange: '', // 时间范围
            streetList: [], // 街道列表
            organList: [], // 机构列表
            staffList: [], // 服务人员列表
            comboOptions: [], // 服务项目列表
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            streetArr: [],
            tableData: [],
            tableParams: {
                pageSize: 10,
                pageNum: 1
            },
            total: 0,
            loading: false,
            orderId: null // 工单ID
        }
    },
    mounted() {
        this.getCountInfo()
        this.getStreetList()
        this.getOrganList()
        this.getServiceList()
        this.getStaffList()
        setInterval(function () {
            // 解决级联选择器不点击圆点选中元素问题
            document.querySelectorAll('.el-cascader-node__label').forEach(el => {
                el.onclick = function () {
                    if (this.previousElementSibling) this.previousElementSibling.click()
                }
            })
        }, 1000)
    },
    methods: {
        getContent,
        // 获取统计数
        getCountInfo() {
            getCount({ bed: this.searchForm.bed, workOrderSourceType: 0 }).then(res => {
                this.list[0].num = res.data.undeterminedWorkOrder
                this.list[1].num = res.data.distributionWorkOrder
                this.list[2].num = res.data.forServiceWorkOrder
                this.list[3].num = res.data.inServiceWorkOrder
                this.list[4].num = res.data.completedWorkOrder
                this.list[5].num = res.data.canceledWorkOrder
            })
        },
        // 获取街道列表
        getStreetList() {
            streetList().then(res => {
                this.streetList = res.data
            })
        },
        // 获取机构列表
        getOrganList() {
            serviceNameList().then(res => {
                this.organList = res.data
            })
        },
        // 获取服务项目列表
        getServiceList() {
            backCascader2().then(res => {
                this.comboOptions = res.data
            })
        },
        // 获取服务人员列表
        getStaffList() {
            hksStaffList().then(res => {
                this.staffList = res.data
            })
        },
        // 更改时间
        dateTimeChange(time) {
            if (time) {
                this.searchForm.startTime = time[0]
                this.searchForm.endTime = time[1]
            } else {
                this.searchForm.startTime = ''
                this.searchForm.endTime = ''
            }
        },
        // 选择服务项目
        changeService(value) {
            if (value.length > 0) {
                this.searchForm.serviceItemId = value[value.length - 1]
            } else {
                this.searchForm.serviceItemId = ''
            }
        },
        // 排序
        sortChangeInit(column) {
            let name = column.prop
            let order = column.order === 'ascending' ? 'asc' : column.order === 'descending' ? 'desc' : null
            let sortParams = { sortType: name, sort: order }
            this.tableParams.pageNum = 1
            this.getList(sortParams)
        },
        handleSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize
            this.doSearch()
        },
        handleCurrentChange(pageNum) {
            this.tableParams.pageNum = pageNum
            this.getList()
        },
        doSearch() {
            this.tableParams.pageNum = 1
            this.getList()
        },
        getList(sortParams) {
            this.loading = true
            const params = { ...this.searchForm, ...this.tableParams, status: +this.activeName, ...sortParams }
            params.streetId = this.streetArr[this.streetArr.length - 1]
            // console.log(params,'@@@@@')
            getWorkOrderPage(params)
                .then(res => {
                    this.tableData = res.data.records
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        // 查看详情
        seeDetail(row) {
            this.orderId = row.workOrderId
            this.orderServiceOgr = row.serviceName
            this.drawerShow = true
        },
        // 导出
        async doExport() {
            const str = getContent(this.WORKOPTIONS, this.activeName, 'label', 'text')
            const res = await WorkOrderPageDown({ ...this.searchForm, status: +this.activeName })
            downloadStream(res, `${str}工单列表.xls`)
        }
    }
}
</script>

<style lang="scss" scoped>
.workList {
    .content {
    }
    .w350 {
        width: 350px;
    }
}
</style>
